<script setup lang="ts">
import { Tab_Filter_Type } from '~/enums/tab_filter-type'
import { RankListApi } from '~/net/apis/ranking_list_api'

const router = useRouter()
const type = useRouteParam<Tab_Filter_Type>('rankType') ?? Tab_Filter_Type.newest
const periodType = useRouteParam<number>('periodType') ?? 1
const onClickLeft = () => history.back()
const pageNum = ref(1)

const titleAry = ['', '观看榜', '收藏榜', '付费榜']
</script>

<template>
  <div>
    <van-nav-bar :title="titleAry[type]" left-arrow @click-left="onClickLeft" />
    <PullRefreshList
      wrap-class="w-full grid grid-cols-2 gap2 px-15px"
      :request-api="RankListApi.query"
      :offset-list="20"
      :request-params="{
        pageNum,
        pageSize: 20,
        rankType: periodType,
        type
      }"
      :data-call-back="(Response) => {
        const data = Response.record;
        Response.record = data.map((item:RankVideo, index:number) => { return { ...item, sort: (index + 1) } })
        //pageNum = pageNum+1;
        return Response;
      }"
    >
      <template #="{ data }">
        <MediaBaseCard :item="data as RankVideo" />
      </template>
    </PullRefreshList>
  </div>
</template>
